'use strict';

const defaultConfig = {
    rows: 0,
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                dots: true,
                infinite: true,
                rows: 0,
                slidesToShow: 3,
                slidesToScroll: 3

            }
        }, {
            breakpoint: 600,
            settings: {
                dots: true,
                infinite: true,
                rows: 0,
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }, {
            breakpoint: 480,
            settings: {
                dots: false,
                infinite: true,
                rows: 0,
                slidesToShow: 2,
                slidesToScroll: 1
            }
        }
    ]
};

const recommendationsDefaultConfig = {
    rows: 0,
    dots: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    autoplay: false,
    autoplaySpeed: 1000,
    responsive: [
        {
            breakpoint: 1140,
            settings: {
                dots: true,
                infinite: true,
                rows: 0,
                slidesToShow: 3,
                slidesToScroll: 1,
                centerMode: false,
                autoplay: false,
                autoplaySpeed: 1000,
                arrows: true
            }
        }, {
            breakpoint: 720,
            settings: {
                dots: true,
                infinite: true,
                rows: 0,
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: false,
                autoplay: false,
                autoplaySpeed: 1000,
                arrows: true
            }
        }, {
            breakpoint: 540,
            settings: {
                dots: true,
                infinite: true,
                rows: 0,
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: false,
                autoplay: false,
                autoplaySpeed: 1000,
                arrows: true
            }
        }
    ]
};

const upsellRecommendationsDefaultConfig = {
    rows: 0,
    dots: false,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: false,
    autoplay: false,
    mobileFist: true,
    responsive: []
};

const einsteinDefaultConfig = {
    rows: 0,
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    autoplay: false,
    autoplaySpeed: 1000,
    responsive: [
        {
            breakpoint: 1140,
            settings: {
                dots: true,
                infinite: true,
                rows: 0,
                slidesToShow: 3,
                slidesToScroll: 1,
                centerMode: false,
                autoplay: false,
                autoplaySpeed: 1000,
                arrows: true
            }
        }, {
            breakpoint: 720,
            settings: {
                dots: true,
                infinite: true,
                rows: 0,
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: false,
                autoplay: false,
                autoplaySpeed: 1000,
                arrows: true
            }
        }, {
            breakpoint: 540,
            settings: {
                dots: false,
                infinite: true,
                rows: 0,
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: false,
                autoplay: false,
                autoplaySpeed: 1000,
                arrows: true
            }
        }
    ]
};

var lensTechnologyConfig = {
    rows: 0,
    dots: false,
    infinite: false,
    slidesToShow: 5,
    centerMode: false,
    autoplay: false,
    autoplaySpeed: 1000,
    arrows: true,
    responsive: [
        {
            breakpoint: 1140,
            settings: {
                dots: false,
                infinite: false,
                rows: 0,
                slidesToShow: 4,
                centerMode: false,
                autoplay: false,
                autoplaySpeed: 1000,
                arrows: true
            }
        }, {
            breakpoint: 992,
            settings: {
                dots: false,
                infinite: false,
                rows: 0,
                slidesToShow: 4,
                centerMode: false,
                autoplay: false,
                autoplaySpeed: 1000,
                arrows: true
            }
        }, {
            breakpoint: 544,
            settings: {
                dots: false,
                infinite: false,
                rows: 0,
                slidesToShow: 5,
                centerMode: false,
                autoplay: false,
                autoplaySpeed: 1000,
                arrows: true
            }
        }, {
            breakpoint: 460,
            settings: {
                dots: false,
                infinite: false,
                rows: 0,
                slidesToShow: 4,
                centerMode: false,
                autoplay: false,
                autoplaySpeed: 1000,
                arrows: true
            }
        }, {
            breakpoint: 365,
            settings: {
                dots: false,
                infinite: false,
                rows: 0,
                slidesToShow: 3,
                centerMode: false,
                autoplay: false,
                autoplaySpeed: 1000,
                arrows: true
            }
        }
    ]
};

/**
 * Dynamically creates Bootstrap carousel from response containing images
 * @param {string} html - The carousel html
 * @param {jQuery} $productContainer - DOM element for a given product
 */
const createProductCarousel = (html, $productContainer) => {
    const $carouselContainer = $productContainer.find('.carousel-container');
    const $oldCarousel = $($carouselContainer).find('.product-carousel');

    $carouselContainer.off('touchstart click', '.slick-paging-btn');
    $oldCarousel.off('beforeChange');
    $oldCarousel.find('.product-carousel').slick('unslick');

    $($carouselContainer)
        .empty()
        .append(html);

    const $carousel = $($carouselContainer).find('.product-carousel');
    initProductCarousel($carousel);
};

/**
 * Initializes the carousel with all the necessary events
 * @param {jQuery} $carousel The jQuery object for the carousel
 */
const initProductCarousel = ($carousel) => {
    const $carouselContainer = $carousel.parents('.carousel-container');
    var carouselSlickConfig = $carouselContainer.data('configuration');
    if (carouselSlickConfig) {
        $carousel.slick(carouselSlickConfig);
    } else {
        $carousel.slick({ rows: 0 });
    }

    $carousel.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        var $slickPaging = $carouselContainer.find('.slick-paging');
        $slickPaging.find('.slick-paging-btn').removeClass('active');
        $slickPaging.find(`.slick-paging-btn:nth-child(${nextSlide + 1})`).addClass('active');
    });

    $carouselContainer.on('touchstart click', '.slick-paging-btn', function () {
        $carousel.slick('slickGoTo', $(this).index());
    });
};

const initCarousel = ($carousel) => {
    if (!$carousel.data('slick')) {
        $carousel.slick(defaultConfig);
    } else {
        $carousel.slick();
    }
};

// recommendations-carousel
const initRecommendationsCarousel = ($carousel) => {
    if (!$carousel.data('slick')) {
        $carousel.slick(recommendationsDefaultConfig);
    } else {
        $carousel.slick();
    }
};

// upsell recommendations-carousel
const initUpsellRecommendationsCarousel = ($carousel) => {
    if (!$carousel.data('slick')) {
        $carousel.slick(upsellRecommendationsDefaultConfig);
    } else {
        $carousel.slick();
    }
};

// recommendations-carousel
const initEinsteinRecommendationsCarousel = ($carousel) => {
    if (!$carousel.data('slick')) {
        $carousel.slick(einsteinDefaultConfig);
    } else {
        $carousel.slick();
    }
};

const initLensTechnologyCarousel = ($carousel) => {
    if (!$carousel.data('slick')) {
        var nbOfItems = $carousel.find('li').length;
        if (nbOfItems >= 5) {
            lensTechnologyConfig.slidesToShow = 5;
        } else if (nbOfItems === 4) {
            lensTechnologyConfig.slidesToShow = 4;
        } else if (nbOfItems === 3) {
            lensTechnologyConfig.slidesToShow = 3;
            lensTechnologyConfig.responsive[0].settings.slidesToShow = 3;
            lensTechnologyConfig.responsive[1].settings.slidesToShow = 3;
            lensTechnologyConfig.responsive[2].settings.slidesToShow = 3;
            lensTechnologyConfig.responsive[3].settings.slidesToShow = 3;
        } else if (nbOfItems === 2) {
            lensTechnologyConfig.slidesToShow = 2;
            lensTechnologyConfig.responsive[0].settings.slidesToShow = 2;
            lensTechnologyConfig.responsive[1].settings.slidesToShow = 2;
            lensTechnologyConfig.responsive[2].settings.slidesToShow = 2;
            lensTechnologyConfig.responsive[3].settings.slidesToShow = 2;
            lensTechnologyConfig.responsive[4].settings.slidesToShow = 2;
        } else if (nbOfItems === 1) {
            lensTechnologyConfig.slidesToShow = 1;
            lensTechnologyConfig.responsive[0].settings.slidesToShow = 1;
            lensTechnologyConfig.responsive[1].settings.slidesToShow = 1;
            lensTechnologyConfig.responsive[2].settings.slidesToShow = 1;
            lensTechnologyConfig.responsive[3].settings.slidesToShow = 1;
            lensTechnologyConfig.responsive[3].settings.slidesToShow = 1;
            lensTechnologyConfig.responsive[4].settings.slidesToShow = 1;
        } else if (nbOfItems === 0) {
            lensTechnologyConfig.slidesToShow = 0;
            lensTechnologyConfig.responsive[0].settings.slidesToShow = 0;
            lensTechnologyConfig.responsive[1].settings.slidesToShow = 0;
            lensTechnologyConfig.responsive[2].settings.slidesToShow = 0;
            lensTechnologyConfig.responsive[3].settings.slidesToShow = 0;
            lensTechnologyConfig.responsive[4].settings.slidesToShow = 0;
        } else {
            lensTechnologyConfig.slidesToShow = $carousel.find('li').length;
        }
        $carousel.slick(lensTechnologyConfig);
    } else {
        $carousel.slick();
    }
};

const init = () => {
    $('.carousel').each(function () {
        initCarousel($(this));
    });

    $('.product-carousel').each(function () {
        initProductCarousel($(this));
    });

    $('.lens-technology-carousel:visible').each(function () {
        /**
         * Select lens technology based on default variation (at page load)
         */
        $(this).on('init', function (event, slick) {
            var selectedVariation = $('#color-vatiations-wrapper .color-value.selected').closest('.color-vatiations-item').attr('data-color-variant');

            // retieve the corresponding lens technology item
            var $currentLensTechnologyItem = $(".variant-technology-image[data-color-variant='" + selectedVariation + "']");
            // scroll to the current active slide
            if ($currentLensTechnologyItem.closest('.lens-technology-image-list').hasClass('slick-initialized')) {
                var currentSlideClasses = $currentLensTechnologyItem.attr('class').split(' '); // get all classes
                var currentSlideClasseName = currentSlideClasses.filter(className => /^custom-order-\d+$/.test(className))[0]; // get the string for the class "order-<number>"
                var currentSlidePosition = parseInt(currentSlideClasseName.split('-')[1], 10); // get the number

                var slidesToShow = slick.options.slidesToShow;
                var startIndex = currentSlidePosition - slidesToShow + 1;
                startIndex = Math.max(startIndex, 0);
                slick.currentSlide = startIndex;
            }
        });
        initLensTechnologyCarousel($(this));
    });

    $('.recommendations-carousel').each(function () {
        initRecommendationsCarousel($(this));
    });

    $('.recommendations-upsell-carousel').each(function () {
        initUpsellRecommendationsCarousel($(this));
    });

    $('body').on('shown.bs.modal', '.modal', function () {
        $(this).find('.product-carousel').each(function () {
            initProductCarousel($(this));
        });
    });

    const observer = new MutationObserver(function (mutations) {
        mutations.forEach((mutation) => {
            if (mutation.addedNodes && mutation.addedNodes.length > 0) {
                const carousels = $(mutation.addedNodes).find('.slot-carousel');
                for (let i = 0, len = carousels.length; i < len; i++) {
                    initEinsteinRecommendationsCarousel($(carousels[i]));
                }
            }
        });
    });

    observer.observe(document.body, { childList: true, subtree: true });
};

module.exports = {
    init,
    methods: {
        createProductCarousel: createProductCarousel,
        initRecommendationsCarousel: initRecommendationsCarousel,
        initUpsellRecommendationsCarousel: initUpsellRecommendationsCarousel,
        initLensTechnologyCarousel: initLensTechnologyCarousel
    }
};
