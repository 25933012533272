'use strict';

module.exports = function () {
    document.addEventListener('lazybeforeunveil', function (e) {
        var bg = e.target.getAttribute('data-bg');
        if (bg) {
            // eslint-disable-next-line no-param-reassign
            e.target.style.backgroundImage = 'url(\'' + bg + '\')';
        }
    });
};
