'use strict';

const toggleMobileNavigation = function () {
    $('body').toggleClass('mobile-nav-open');
    $('.modal-background').toggleClass('open');
};

// calculate the top padding for the main section
var calculateMainSectionPadding = function () {
    if ($('.site-header_slim .navbar > div').length <= 0) { 
        var preheaderHeight = $('.preheader').height() || 0;
        var storeSwitcherHeight = $('.store-switcher-wrapper').outerHeight() || 0;
        var nonStickyHeaderHeight = $('.non-sticky-header').height() || 0;
        var stickyHeaderHeight = $('.sticky-header').height() || 0;
        var permanentBannerHeight = $('.permanent-header-banner').outerHeight() || 0;
        $('.main-content').css('padding-top', preheaderHeight + storeSwitcherHeight + nonStickyHeaderHeight + stickyHeaderHeight + permanentBannerHeight);
    }
};

module.exports = function () {
    $(window).resize(function () {
        calculateMainSectionPadding();
    });

    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
    $('.preheader .promo-banner_close').on('click', function () {
        $('.preheader').addClass('d-none').parents('body').removeClass('banner-promo_active');
        window.sessionStorage.setItem('hide_header_banner', '1');
        // calculate the top padding for the main section
        calculateMainSectionPadding();
    });

    $(window).on('load', function () {
        if (!headerBannerStatus || headerBannerStatus < 0) {
            $('.preheader').removeClass('d-none').parents('body').addClass('banner-promo_active');
            // calculate the top padding for the main section
            calculateMainSectionPadding();
        }
    });

    $('.dropdown a.active').each(function () {
        const $parent = $(this).parents('.dropdown');
        $parent.find('.dropdown-toggle').text($(this).text());
    });

    $('.mega-menu__item')
        .on('mouseenter', function () {
            $(this).addClass('mega-menu_open');
            $(this)
                .find('.mega-menu__sub')
                .attr('aria-expanded', true);
        })
        .on('mouseleave', function () {
            $(this).removeClass('mega-menu_open');
            $(this)
                .find('.mega-menu__sub')
                .attr('aria-expanded', false);
        });

    $('body').on('click', '.modal-background', function () {
        if ($('body').hasClass('mobile-nav-open')) {
            toggleMobileNavigation();
        }
    });

    $('[data-toggle="slide-collapse"]').on('click', function () {
        if (!$('body').hasClass('mobile-nav-open')) {
            $(window).scrollTop(0);
        }

        toggleMobileNavigation();
    });

    $('.mobile-nav-forward').on('click', function () {
        $(this)
            .siblings('.level-2, .level-3')
            .addClass('active');
    });

    $('.mobile-nav-previous').on('click', function () {
        $(this)
            .closest('.level-2, .level-3')
            .removeClass('active');
    });

    $('body').on('calculatemainsection', calculateMainSectionPadding);
};

const siteHeaderHeight = $('.site-header').height();
const $body = $('body');
if ($body.hasClass('permanent-sticky')) {
    $(window).on('load', function () {
        calculateMainSectionPadding();
    });
} else {
    $(window).on('load', function () {
        calculateMainSectionPadding();

        $(window).on('scroll', function () {
            if ($(this).scrollTop() > siteHeaderHeight) {
                $body.addClass('minify-header');
            } else {
                $body.removeClass('minify-header');
            }
            calculateMainSectionPadding();
        });
    });
}
$(window).trigger('scroll');
