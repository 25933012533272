'use strict';

var clientSideValidation = require('base:components/clientSideValidation');

/**
 * Remove current element validation.
 * @param {element} - element to be cleared
 * @returns {void}
 */
function clearFieldError(element) {
    $(element).removeClass('is-invalid');
    if($(element).is('#phone') || $(element).is('#shippingPhoneNumber') || $(element).is('#shippingPhoneNumberdefault') || $(element).is('#phoneNumber')) {
        $(element).parent('.iti').removeClass('invalidField');
    }
    $(element).parent('.input-group').parent().find('.invalid-feedback')
        .empty();

    $('.submit-shipping, .submit-payment, .place-order').removeAttr('disabled');
}

/**
 * Validate form field. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 */
function validationField(element, event) {
    var valid = true;
    
    if ($(element).hasClass('flatpickr-input')) {
        var $invalidFeedback = $(element).parents('.form-group').find('.invalid-feedback');
        if ($(element).attr('required') && $(element).val() === '') {
            $invalidFeedback.text($(element).attr('data-missing-error'));
            $invalidFeedback.show();
            $(element).addClass('is-invalid');
            valid = false;
        } else {
            $invalidFeedback.hide();
            $(element).removeClass('is-invalid');
        }
    }
    if (element && element.checkValidity && !element.checkValidity()) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        if (!element.validity.valid) {
            $(element).trigger('invalid', element.validity);
        }
    }
    if (valid) {
        clearFieldError(element);
    }

}

clientSideValidation.invalid = function () {
    $('form input, form select, form textarea').on('invalid', function (e) {
        e.preventDefault();
        
        if ($(this).hasClass('cardNumber') && this.validity.customError) {
            var supportedCards = $('.supported-cards') ? $('.supported-cards').attr('data-cards') : '';
            var element = document.querySelector('.cardNumber');
            var cardType = $('#cardType').val();
            if (cardType !== 'Unknown' && supportedCards && supportedCards.indexOf(cardType) === -1) {
                
                var cardsError = $('.supported-cards') ? $('.supported-cards').attr('data-cards-error') : '';
                $(this).parents('.form-group').find('.invalid-feedback').text(cardsError).show();
                $(this).addClass('is-invalid');
            } else {
                clearFieldError(this);
                $(this).parents('.form-group').find('input.cardNumber').removeClass('is-invalid');
                $('#cardType').removeClass('is-invalid');
            }
        } else {
            this.setCustomValidity('');
            if (!this.validity.valid) {
                var validationMessage = this.validationMessage;
                $(this).addClass('is-invalid');
                if ($(this).is('#phone') || $(this).is('#shippingPhoneNumber') || $(this).is('#shippingPhoneNumberdefault') || $(this).is('#phoneNumber')) {
                    $(this).parent('.iti').addClass('invalidField');
                }
                if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                    validationMessage = $(this).data('pattern-mismatch');
                }
                if ((this.validity.rangeOverflow || this.validity.rangeUnderflow)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if ((this.validity.tooLong || this.validity.tooShort)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if (this.validity.valueMissing && $(this).data('missing-error')) {
                    validationMessage = $(this).data('missing-error');
                }
                $(this).parents('.form-group').find('.invalid-feedback')
                    .text(validationMessage);
            }
        }
    });
};

clientSideValidation.focusout = function () {
    $('form .required').on('keyup', 'input, select', function (e) {
        if (e.keyCode !== 9) { // escape tab key
            validationField(this, e);
        }
    });

    $('form .required').on('blur', 'input, select', function (e) {
        validationField(this, e);
    });

    $('form .required').on('focusout', 'input', function (e) {
        validationField(this, e);
    });
};

clientSideValidation.buttonClick = function () {
    $('form button[type="submit"], form input[type="submit"]').on('click', function () {
        if (!$(this).parents('form').find('.is-invalid').length) {
            // clear all errors when trying to submit the form
            clientSideValidation.functions.clearForm($(this).parents('form'));
        }
    });
};

clientSideValidation.validationAddressFields = function () {
    $('.validate-this-field').on('keydown', function(e) {
        var regex = $(this).closest('form').attr('data-address-regex');
        if (regex) {
            var typedKey = e.key;
            if (typeof typedKey !== 'undefined') {
                var result = typedKey.match(new RegExp(regex, 'gi'));
                if (result && result.length) {
                    return false;
                }
            }
        }
        return true;
    });
};

module.exports = clientSideValidation;
