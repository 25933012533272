'use strict';

const base = require('~/product/base');
const focusHelper = require('base:components/focus');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl
    += (newUrl.indexOf('?') !== -1 ? '&' : '?')
    + Object.keys(params)
        .map(function (key) {
            return key + '=' + encodeURIComponent(params[key]);
        })
        .join('&');

    return newUrl;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error '
        + 'fade show" role="alert">'
        + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
        + '<span aria-hidden="true">&times;</span>'
        + '</button>'
        + data.valid.message
        + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            $('.cart')
                .empty()
                .append(
                    '<div class="row"> '
            + '<div class="col-12 text-center"> '
            + '<h1>'
            + data.resources.emptyCartMsg
            + '</h1> '
            + '</div> '
            + '</div>'
                );
            $('.number-of-items')
                .empty()
                .append(data.resources.numberOfItems);
            $('.minicart-quantity')
                .empty()
                .append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items')
        .empty()
        .append(data.resources.numberOfItems);
    $('.shipping-cost')
        .empty()
        .append(data.totals.totalShippingCost);
    $('.tax-total')
        .empty()
        .append(data.totals.totalTax);
    $('.grand-total')
        .empty()
        .append(data.totals.grandTotal);
    $('.sub-total')
        .empty()
        .append(data.totals.subTotal);
    $('.minicart-quantity')
        .empty()
        .append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('d-none');
        $('.order-discount-total')
            .empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('d-none');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('d-none');
        $('.shipping-discount-total')
            .empty()
            .append('- ' + data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('d-none');
    }

    data.items.forEach(function (item) {
        $('.item-' + item.UUID)
            .empty()
            .append(item.renderedPromotions);
        $('.item-total-' + item.UUID)
            .empty()
            .append(item.priceTotal.renderedPrice);
    });
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error '
    + 'fade show" role="alert">'
    + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
    + '<span aria-hidden="true">&times;</span>'
    + '</button>'
    + message
    + '</div>';

    $('.cart-error').append(errorHtml);
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="single-approaching-discount text-center">' + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    $('.availability-' + lineItem.UUID).empty();

    if (lineItem.availability) {
        if (lineItem.availability.messages) {
            lineItem.availability.messages.forEach(function (message) {
                var className = message.replace(/\s/g, '-').toLowerCase();
                if (message === window.app.preferences.inStockLabel) {
                    className += ' in-stock';
                }
                messages += '<div class="line-item-availability-status ' + className + '">' + message + '</div>';
            });
        }

        if (lineItem.availability.inStockDate) {
            messages
        += '<div class="line-item-availability-status line-item-instock-date">' + lineItem.availability.inStockDate + '</div>';
        }
    }

    $('.availability-' + lineItem.UUID).html(messages);
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    var lineItem = data.cartModel.items.find(function (item) {
        return item.UUID === uuid;
    });

    if (lineItem.variationAttributes) {
        var colorAttr = lineItem.variationAttributes.find(function (attr) {
            return attr.attributeId === 'color';
        });

        if (colorAttr) {
            var colorSelector = '.Color-' + uuid + ' .line-item-attribute-value';
            $(colorSelector).text(colorAttr.displayValue);
        }

        var sizeAttr = lineItem.variationAttributes.find(function (attr) {
            return attr.attributeId === 'size';
        });

        if (sizeAttr) {
            var sizeSelector = '.Size-' + uuid + ' .line-item-attribute-value';
            $(sizeSelector).text(sizeAttr.displayValue);
        }

        var imageSelector = '.product-info.uuid-' + uuid + ' .item-image img';
        $(imageSelector).attr('src', lineItem.images.small[0].url);
        $(imageSelector).attr('alt', lineItem.images.small[0].alt);
        $(imageSelector).attr('title', lineItem.images.small[0].title);
    }

    if (lineItem.options && lineItem.options.length) {
        var option = lineItem.options[0];
        var optSelector = '.lineItem-options-values[data-option-id="' + option.optionId + '"]';
        $(optSelector).data('value-id', option.selectedValueId);
        $(optSelector + ' .line-item-attributes').text(option.displayName);
    }

    var qtySelector = '.quantity[data-uuid="' + uuid + '"]';
    $(qtySelector).find('.qty-card-quantity-count').text(lineItem.quantity);
    $(qtySelector).data('pid', data.newProductId);

    $('.remove-product[data-uuid="' + uuid + '"]').data('pid', data.newProductId);

    var priceSelector = '.line-item-price-' + uuid + ' .sales .value';
    $(priceSelector).text(lineItem.price.sales.formatted);
    $(priceSelector).attr('content', lineItem.price.sales.decimalPrice);

    if (lineItem.price.list) {
        var listPriceSelector = '.line-item-price-' + uuid + ' .list .value';
        $(listPriceSelector).text(lineItem.price.list.formatted);
        $(listPriceSelector).attr('content', lineItem.price.list.decimalPrice);
    }
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    var htmlString = '<!-- Modal -->'
    + '<div class="modal fade" id="editProductModal" tabindex="-1" role="dialog">'
    + '<span class="enter-message sr-only"></span>'
    + '<div class="modal-dialog quick-view-dialog modal-xl">'
    + '<!-- Modal content-->'
    + '<div class="modal-content">'
    + '<div class="modal-header">'
    + '    <button type="button" class="close pull-right" data-dismiss="modal">'
    + '        <span aria-hidden="true">&times;</span>'
    + '        <span class="sr-only"> </span>'
    + '    </button>'
    + '</div>'
    + '<div class="modal-body"></div>'
    + '<div class="modal-footer"></div>'
    + '</div>'
    + '</div>'
    + '</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $('.modal-body')
        .spinner()
        .start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $('#editProductModal').modal('show');

            $('body').trigger('editproductmodal:ready');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Removes a product
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} uuid - uuid
 */
function removeProduct(actionUrl, productID, uuid) {
    var url = actionUrl;
    var urlParams = {
        pid: productID,
        uuid: uuid
    };

    url = appendToUrl(url, urlParams);

    $.spinner().start();
    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        success: function (data) {
            if (data.basket.items.length === 0) {
                $('.cart')
                    .empty()
                    .append(
                        '<div class="row"> '
                + '<div class="col-12 text-center"> '
                + '<h1>'
                + data.basket.resources.emptyCartMsg
                + '</h1> '
                + '</div> '
                + '</div>'
                    );
                $('.number-of-items')
                    .empty()
                    .append(data.basket.resources.numberOfItems);
                $('.minicart-quantity')
                    .empty()
                    .append(data.basket.numItems);
                $('.minicart-link').attr({
                    'aria-label': data.basket.resources.minicartCountOfItems,
                    title: data.basket.resources.minicartCountOfItems
                });
            } else {
                if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                    for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                        $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                    }
                }
                $('.uuid-' + uuid).remove();
                if (!data.basket.hasBonusProduct) {
                    $('.bonus-product').remove();
                }
                $('.coupons-and-promos')
                    .empty()
                    .append(data.basket.totals.discountsHtml);
                updateCartTotals(data.basket);
                updateApproachingDiscounts(data.basket.approachingDiscounts);
                $('body').trigger('setShippingMethodSelection', data.basket);
                validateBasket(data.basket);
            }

            $('body').trigger('cart:update');

            $.spinner().stop();
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(err.responseJSON.errorMessage);
                $.spinner().stop();
            }
        }
    });
}

module.exports = function () {
    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var uuid = $(this).data('uuid');
        removeProduct(actionUrl, productID, uuid);
    });

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        removeProduct(data.actionUrl, data.productID, data.uuid);
    });

    $('.optional-promo').click(function (e) {
        e.preventDefault();
        $(this).toggleClass('collapsed');
        $('.promo-code-form').toggle();
    });

    /**
 * Generate HTML for a bonus product line item.
 * @param {Object} item - Bonus product item data
 * @returns {string} - Generated HTML string for the bonus product
 */
    function generateBonusProductHtml(item) {
        return `
        <div class="product-info bonus-product-line-item uuid-${item.UUID} p-2 p-md-4">
            <div class="row ">
                <div class="col-12 col-md-7 product-card-header">
                    <div class="d-flex flex-row">
                        <div class="item-image">
                            <a href="${item.url}">
                                <img class="product-image" src="${item.images.small[0].url}" alt="${item.images.small[0].alt}" title="${item.images.small[0].title}">
                            </a>
                        </div>
                        <div class="product-details">
                            <div>Bonus Product</div>

                            <div class="line-item-header">
                                <div class="line-item-name">
                                    <a href="${item.url}" class="d-inline-block text-reset text-truncate">${item.productName}</a>
                                </div>
                            </div>

                            <div class="item-attributes d-flex flex-column">
                                <div class="line-item-attributes Color-${item.UUID}">
                                    <span class="font-weight-bold">Color</span>:
                                    <span class="line-item-attribute-value">${item.variationAttributes[0].displayValue}</span>
                                </div>

                                <div class="line-item-quanity-info quantity" data-uuid="${item.UUID}" data-pid="${item.id}">
                                    <span class="qty-card-quantity-label">Quantity</span>:
                                    <span class="qty-card-quantity-count">${item.quantity}</span>
                                </div>

                                <div class="line-item-availability availability-${item.UUID}">
                                    <div class="line-item-availability-status in-stock in-stock">In Stock</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-2 d-flex flex-column justify-content-between align-items-end product-card-footer">
                    <div class="d-md-block"></div>
                    <div class="w-50 w-md-75 mb-2 quantity-form"></div>
                </div>
                <div class="col-12 col-md-3 d-flex flex-column justify-content-between align-items-end product-card-footer">
                    <div class="d-none d-md-block"></div>
                    <div></div>
                    <div class="d-block d-md-none"></div>
                </div>
            </div>
        </div>`;
    }
    /**
     * Updates bonus products in the cart based on AJAX response.
     * @param {Object} data - AJAX response from the server
     */
    function updateBonusProducts(data) {
        const currentBonusProducts = $('.cart-products .bonus-product-line-item');
        const updatedBonusUUIDs = [];
    
        if (data.hasBonusProduct) {
            // Loop through the items and check for bonus products
            data.items.forEach(function (item) {
                if (item.isBonusProductLineItem) {
                    const bonusProductHtml = generateBonusProductHtml(item);
                    const $bonusProduct = $('.uuid-' + item.UUID);
    
                    // Track the UUIDs of the bonus products in the updated data
                    updatedBonusUUIDs.push(item.UUID);
    
                    if ($bonusProduct.length > 0) {
                        // If bonus product already exists, update it
                        $bonusProduct.replaceWith(bonusProductHtml);
                    } else {
                        // If it's a new bonus product, append it to the cart
                        $('.cart-products').append(bonusProductHtml);
                    }
                }
            });
    
            // Remove any bonus products that are not in the updated data
            currentBonusProducts.each(function () {
                const uuid = $(this).data('uuid');
                if (!updatedBonusUUIDs.includes(uuid)) {
                    $(this).remove();
                }
            });
        } else {
            // If there are no bonus products, remove all existing bonus products
            currentBonusProducts.remove();
        }
    }

    $('body').on('change', '.quantity-form > .quantity', function () {
        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');

        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = appendToUrl(url, urlParams);

        $(this)
            .parents('.product-info')
            .spinner()
            .start();

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                $('.coupons-and-promos')
                    .empty()
                    .append(data.totals.discountsHtml);
                updateCartTotals(data);
                updateBonusProducts(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                updateAvailability(data, uuid);
                validateBasket(data);
                $(this).data('pre-select-qty', quantity);
                var $productDetailsSection = $(this).parents('.product-info').find('.product-details');
                $productDetailsSection.find('.qty-card-quantity-count').html(quantity);
                $('body').trigger('cart:update');

                $.spinner().stop();
                if (
                    $(this)
                        .parents('.product-info')
                        .hasClass('bonus-product-line-item')
          && $('.cart-page').length
                ) {
                    location.reload();
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this)
                .find(':selected')
                .attr('data-shipping-id')
        };
        // url = appendToUrl(url, urlParams);

        $('.totals')
            .spinner()
            .start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos')
                        .empty()
                        .append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                }
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.promo-code-form').submit(function (e) {
        e.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        var $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message')
                        .empty()
                        .append(data.errorMessage);
                    $('body').trigger('promotion:error', data);
                } else {
                    $('.coupons-and-promos')
                        .empty()
                        .append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    $('body').trigger('promotion:success', data);
                }
                $('.coupon-code-field').val('');
                $.spinner().stop();
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var url = $('.coupons-and-promos').data('action');
        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                validateBasket(data);
                $.spinner().stop();
                $('body').trigger('promotion:success', data);
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function (e) {
        e.preventDefault();

        var editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $('body').on('shown.bs.modal', '#editProductModal', function () {
        $('#editProductModal')
            .siblings()
            .attr('aria-hidden', 'true');
        $('#editProductModal .close').focus();
    });

    $('body').on('hidden.bs.modal', '#editProductModal', function () {
        $('#editProductModal')
            .siblings()
            .attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#editProductModal', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#editProductModal',
            firstElementSelector: '.close',
            lastElementSelector: '.update-cart-product-global',
            nextToLastElementSelector: '.modal-footer .quantity-select'
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $('body').on('product:updateAddToCart', function (e, response) {
    // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer).closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr(
            'disabled',
            !$('.global-availability', dialog).data('ready-to-order') || !$('.global-availability', dialog).data('available')
        );
    });

    $('body').on('product:updateAvailability', function (e, response) {
    // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);

        var dialog = $(response.$productContainer).closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            var allAvailable = $('.product-availability', dialog)
                .toArray()
                .every(function (item) {
                    return $(item).data('available');
                });

            var allReady = $('.product-availability', dialog)
                .toArray()
                .every(function (item) {
                    return $(item).data('ready-to-order');
                });

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog)
                .empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', function (e, response) {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show')
                .find(response.container)
                .data('pid', response.data.product.id);
            $('.modal.show')
                .find(response.container)
                .find('.product-id')
                .text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $('body').on('change', '.options-select', function () {
        var selectedOptionValueId = $(this).children('option:selected').data('value-id');
        $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        var updateProductUrl = $(this)
            .closest('.cart-and-ipay')
            .find('.update-cart-url')
            .val();
        var selectedQuantity = $(this)
            .closest('.cart-and-ipay')
            .find('.update-cart-url')
            .data('selected-quantity');
        var selectedOptionValueId = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-option');
        var uuid = $(this)
            .closest('.cart-and-ipay')
            .find('.update-cart-url')
            .data('uuid');

        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId: selectedOptionValueId
        };

        $(this)
            .parents('.card')
            .spinner()
            .start();
        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    $('#editProductModal').modal('hide');

                    $('.coupons-and-promos')
                        .empty()
                        .append(data.cartModel.totals.discountsHtml);
                    updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $('.uuid-' + data.uuidToBeDeleted).remove();
                    }

                    validateBasket(data.cartModel);

                    $('body').trigger('cart:update');

                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    base.selectAttribute();
    base.buttonAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
};
