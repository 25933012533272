'use strict';

var base = require('base:components/footer');
var clientSideValidation = require('./clientSideValidation');

/**
 * Toggle the back to top button
 */
function toggleScrollToTop() {
    var currentPosition = $(window).scrollTop();
    if (currentPosition > 110) {
        $('.back-to-top').addClass('show').attr('aria-hidden', false).prop('disabled', false);
    } else {
        $('.back-to-top').removeClass('show').attr('aria-hidden', true).prop('disabled', true);
    }
}

function validateForm(event) {
    var valid = true;
    if (this.checkValidity && !this.checkValidity()) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        $(this).find('input, select').each(function () {
            if (!this.validity.valid) {
                $(this).trigger('invalid', this.validity);
            }
        });
    }
    return valid;
}

module.exports = {
    base: base,
    backToTop: function () {
        if ($('.back-to-top').length) {
            $(window).on('scroll', toggleScrollToTop);
            toggleScrollToTop();
        }
    },
    newsletterfooter: function () {
        $('#validate-privacypolicy').on('change', function (e) {
            if ($(this).is(':checked')) {
                $(this).parent().find('.validate-custom-checkbox').html('');
            }
        });

        $('.subscribe-email-footer').on('click', function (e) {
            e.preventDefault();

            var isvalid = validateForm.call($(this).parents('.email-signup')[0], e);
            if (isvalid) {
                var $button = $('.subscribe-email-footer.btn');
                var url = $button.data('href');
                var emailId = $('input[name=hpEmailSignUp]').val();
                $.spinner().start();
                $button.attr('disabled', true);

                if (grecaptcha) {
                    grecaptcha.ready(function() {
                        grecaptcha.execute(window.app.preferences.reCaptchaSiteKey, { action: 'submit' }).then(function(token) {
                            $.ajax({
                                url: url,
                                type: 'post',
                                dataType: 'json',
                                data: {
                                    emailId: emailId,
                                    reCaptchaToken: token
                                },
                                success: function (data) {
                                    if (data.success) {
                                        $('.email-signup .warn').html(data.msg).removeClass('warn-danger warn-success d-none').addClass('warn-success');
                                        $button.attr('disabled', true);
                                        dataLayer = dataLayer || [];
                                        dataLayer.push({
                                            event: 'clickFooterNewsletter',
                                            result: 1
                                        });
                                    } else {
                                        $('.email-signup .warn').html(data.msg).removeClass('warn-danger warn-success d-none').addClass('warn-danger');
                                        $button.removeAttr('disabled');
                                        dataLayer = dataLayer || [];
                                        dataLayer.push({
                                            event: 'clickFooterNewsletter',
                                            result: 0
                                        });
                                    }
                                    $.spinner().stop();
                                    return false;
                                },
                                error: function (err) {
                                    $('.email-signup .warn').html(err.msg).removeClass('warn-danger warn-success d-none').addClass('warn-danger');
                                    $button.removeAttr('disabled');
                                    dataLayer = dataLayer || [];
                                    dataLayer.push({
                                        event: 'clickFooterNewsletter',
                                        result: 0
                                    });
                                    $.spinner().stop();
                                    return false;
                                }
                            });
                        });
                    });
                }
                $button.removeAttr('disabled');
                return false;
            }
        });
    }
};
