'use strict';

dataLayer = dataLayer || [];

const productImpressions = [];
let scrollTimeout;

const isVisible = function ($el) {
    const elementTop = $el.offset().top;
    const elementBottom = elementTop + $el.outerHeight();
    const viewportTop = $(window).scrollTop();
    const viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
};

var index = 0;
const trackImpressions = function () {
    const impressions = [];
    const items = [];
    $('.product').each(function () {
        const pid = $(this).data('pid');
        var dataLayerInfo = $(this).attr('data-datalayer');
        if (pid && isVisible($(this)) && !productImpressions.includes(pid)) {
            productImpressions.push(pid);
            impressions.push({
                id: pid
            });
            var dataLayerInfoObj = JSON.parse(dataLayerInfo);
            dataLayerInfoObj.index = index;
            items.push(dataLayerInfoObj);
            index += 1;
        }
    });

    if (app.preferences.GA4Enabled) {
        if (impressions.length) {
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                event: 'view_item_list',
                ecommerce: {
                    items
                }
            });
        }
    }
    if (app.preferences.GA3Enabled) {
        if (impressions.length) {
            dataLayer.push({
                ecommerce: {
                    impressions
                }
            });
        }
    }
};

const onScrollFinished = function () {
    clearTimeout(scrollTimeout);
    trackImpressions();
};

module.exports = function () {
    $(document).on('scroll', function () {
        scrollTimeout = setTimeout(onScrollFinished, 500);
    });

    $('body .quote-link').on('click', function (e) {
        var productInfoCtr = $(this).parents('.pdp-primary-info').find('.pdp-quote-button');
        if (productInfoCtr.length > 0) {
            var productInfo = productInfoCtr.attr('data-product-info');
            if (productInfo.length > 0) {
                var productInfoObj = JSON.parse(productInfo);
                dataLayer.push({
                    event: 'quoteClick',
                    productId: productInfoObj.item_id,
                    productName: productInfoObj.item_name,
                    category1: productInfoObj.item_category,
                    category2: productInfoObj.item_category2,
                    category3: productInfoObj.item_category3
                });
            }
        }
    });

    $('body .pdp-quote-button').on('click', 'a', function (e) {
        var productInfo = $(this).parent().attr('data-product-info');
        if (productInfo.length > 0) {
            var productInfoObj = JSON.parse(productInfo);
            dataLayer.push({
                event: 'quoteClick',
                productId: productInfoObj.item_id,
                productName: productInfoObj.item_name,
                category1: productInfoObj.item_category,
                category2: productInfoObj.item_category2,
                category3: productInfoObj.item_category3
            });
        }
    });

    $('form.quote').on('submit', function (e) {
        var productInfo = $(this).attr('data-product-info');
        if (productInfo.length > 0) {
            var productInfoObj = JSON.parse(productInfo);
            dataLayer.push({
                event: 'quoteConfirmed',
                productId: productInfoObj.item_id,
                productName: productInfoObj.item_name,
                category1: productInfoObj.item_category,
                category2: productInfoObj.item_category2,
                category3: productInfoObj.item_category3
            });
        }
    });
    $('body').on('click', '.product a', function (e) {
        const items = [];
        const id = $(this).parents('.product').data('pid');

        if (app.preferences.GA4Enabled) {
            var dataLayerInfo = $(this).parents('.product').attr('data-datalayer');
            var dataLayerInfoObj = JSON.parse(dataLayerInfo);
            dataLayerInfoObj.index = Math.ceil($(this).parents('.product').index() / 2) - 1;
            items.push(dataLayerInfoObj);
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                event: 'select_item',
                ecommerce: {
                    items
                }
            });
        }
        if (app.preferences.GA3Enabled) {
            dataLayer.push({
                event: 'productClick',
                ecommerce: {
                    click: {
                        products: [{
                            id: id
                        }]
                    }
                }
            });
        }
    });

    $('body').on('click', '.add-to-cart:not(.redirect-to-quote)', function (e) {
        const id = $(this).data('pid');
        if (app.preferences.GA4Enabled) {
            var dataLayerInfo = $(this).attr('data-datalayer');
            const items = [];
            var dataLayerInfoObj = JSON.parse(dataLayerInfo);
            dataLayerInfoObj.index = 0;
            dataLayerInfoObj.quantity = 1;
            items.push(dataLayerInfoObj);
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
                event: 'add_to_cart',
                ecommerce: {
                    items
                }
            });
        }
        if (app.preferences.GA3Enabled) {
            dataLayer.push({
                event: 'productClick',
                ecommerce: {
                    add: {
                        products: [{
                            id: id
                        }]
                    }
                }
            });
        }
    });

    $('body').on('click', '.nav-item > a', function (e) {
        var isMobileView = !!$('.navbar-brand .navbar-toggler:visible').length;
        if (isMobileView) {
            if ($(this).parent().hasClass('mobile-view')) {
                if (typeof $(this).parent().attr('data-level') !== 'undefined') {
                    dataLayer.push({
                        event: 'clickNavigation',
                        level: $(this).parent().attr('data-level'),
                        elementName: $(this).parent().attr('data-label').trim()
                            .toLowerCase()
                    });
                }
            }
        } else if (typeof $(this).parent().attr('data-level') !== 'undefined') {
            dataLayer.push({
                event: 'clickNavigation',
                level: $(this).parent().attr('data-level') || 'level 1',
                elementName: $(this).parent().attr('data-label').trim()
                    .toLowerCase()
            });
        }
    });

    $('body').on('click', '.flyout-menu-slot > div', function (e) {
        dataLayer.push({
            merchantName: $(this).attr('class')
        });
    });

    $('body').on('click', '.experience-assets-banner', function (e) {
        dataLayer.push({
            event: 'clickBanner',
            bannerName: $(this).find('.banner-heading').text().trim()
        });
    });

    $('body').on('search:filter', '.refinement-link', function (e) {
        dataLayer.push({
            event: 'clickLPfilter',
            pageType: $('.search-result-count').length > 0 ? 'search page' : 'list page',
            filterName: $(this).parents('.refinement-body').attr('id').replace('refinement-', ''),
            filterValue: $(this).parents('[data-value]').attr('data-value')
        });
    });
    $('body').on('click', '.price-attribute', function (e) {
        dataLayer.push({
            event: 'clickLPfilter',
            pageType: $('.search-result-count').length > 0 ? 'search page' : 'list page',
            filterName: 'price',
            filterValue: $(this).attr('data-value')
        });
    });

    $('.search-results').on('change', '[name=sort-order]', function (e) {
        var optionSelected = $('option:selected', this);
        dataLayer.push({
            event: 'clickSort',
            sortLabel: optionSelected.attr('data-id')
        });
    });

    $('body').on('click', '.category-promo > div', function (e) {
        dataLayer.push({
            event: 'clickMerchantTile',
            tileName: $(this).attr('class')
        });
    });

    trackImpressions();

    if ($('#rx-container').length > 0 && $('#rx-container').attr('data-current-step') === '5') {
        dataLayer.push({
            event: 'Etape5'
        });
    }
};
