'use strict';

var hotspotsVisible = true;
let htsptbtns = document.getElementsByClassName('Hotspot hs-element');

function mvHoverOff() {
    hotspotsVisible = false;
    document.getElementById('btn-toggle').style.backgroundImage = "url('https://dky5neca2x8co.cloudfront.net/Bolle/model-viewers/common/images/info-icon2.png')";
    let list= document.getElementsByClassName('hs-element');
    for (var i = 0; i < list.length; i++) {
        list[i].style.visibility= "hidden";
    }
}

function mvHoverOn() {
    hotspotsVisible = true;
    document.getElementById('btn-toggle').style.backgroundImage = "url('https://dky5neca2x8co.cloudfront.net/Bolle/model-viewers/common/images/info-icon.png')";
    let list= document.getElementsByClassName('hs-element');
    for (var i = 0; i < list.length; i++) {
        list[i].style.visibility= "visible";
    }
}

window.toggleHotspots = function() {
    if(hotspotsVisible) mvHoverOff();
    else mvHoverOn();
}

window.startWiggle = function() {
    $('model-viewer').attr('interaction-prompt', "auto");
}

window.stopWiggle = function() {
    $('model-viewer').attr('interaction-prompt', "none");
}

window.hs_show = function(id) {
    document.getElementById(id).style.display = "block";
    document.getElementById(id).style.animationName = "slide-in";
    document.getElementById(id).style.bottom = "0";
    
    // hide btns
    for(var i=0; i<htsptbtns.length; i++){
        htsptbtns[i].style.backgroundColor = "transparent";
        htsptbtns[i].style.animationName = "none";
    }
    
}

window.hs_hide  = function(id) {
    document.getElementById(id).style.display = "none";
    document.getElementById(id).style.animationName = "slide-out";
    document.getElementById(id).style.bottom = "-500";

    // show btns
    for(var i=0; i<htsptbtns.length; i++){
        htsptbtns[i].style.backgroundColor = "";
        htsptbtns[i].style.animationName = "breathe";
    }
}

// Bind 3d Modal
const bind3dModel = () => {
    $(window).on('resize', function () {
        var $modelEle = $('.product-carousel .model-container');
        setTimeout(() => {
            $('model-viewer').css({
                'width' : $modelEle.width(),
                'height' : $modelEle.width()
            })
        }, 200);
    });
    $('.product-sticker-3d').on('click', function(event) {
        event.preventDefault();
        $('.slick-paging-3d').addClass('showcase-ar').trigger('click');
    });
    $('.product-carousel').on('afterChange', function(event, slick, currentSlide, nextSlide) {
        var $currentActiveContainer = $('.carousel-item[data-slick-index="' + currentSlide + '"');
        if ($currentActiveContainer.hasClass('model-container')) {
            $('.product-carousel').slick('slickSetOption', 'swipe', false, false);
            var $modelEle = $('.product-carousel .model-container');

            //If model is already loaded, then return
            if ($modelEle.hasClass('model-loaded')) return;

            var modelViewer = $currentActiveContainer.find('.model-viewer').get(0);
            var progress = $currentActiveContainer.find('.model-progress').get(0);
            var bar = progress.querySelector('.bar');
    
            modelViewer.addEventListener('progress', (event) => {
                var { totalProgress } = event.detail;
                progress.classList.toggle('show', totalProgress < 1);
                bar.style.transform = `scaleX(${totalProgress})`;
            });
    
            //3d model js files
            var script1 = document.createElement("script");
            var script2 = document.createElement("script");
            var script3 = document.createElement("script");
            var script4 = document.createElement("script");
            var script5 = document.createElement("script");
            var script6 = document.createElement("script");
            var script7 = document.createElement("script");
            var script8 = document.createElement("script");
            script1.type = "text/javascript";
            script2.type = "text/javascript";
            script3.type = "text/javascript";
            script4.type = "text/javascript";
            script5.type = "text/javascript";
            script6.type = "module";
            script7.type = "text/javascript";
            script8.type = "text/javascript";
            script1.src="https://unpkg.com/@webcomponents/webcomponentsjs@2.1.3/webcomponents-loader.js"
            script2.src="https://unpkg.com/intersection-observer@0.5.1/intersection-observer.js"
            script3.src="https://unpkg.com/resize-observer-polyfill@1.5.0/dist/ResizeObserver.js"
            script4.src="https://unpkg.com/fullscreen-polyfill@1.0.2/dist/fullscreen.polyfill.js"
            script5.src="https://unpkg.com/focus-visible@5.1.0/dist/focus-visible.js"
            script6.src="https://dky5neca2x8co.cloudfront.net/model-viewer-public/model-viewer.min.js"
            script7.src="https://dky5neca2x8co.cloudfront.net/model-viewer-public/timeme.min.js"
            script8.src="https://dky5neca2x8co.cloudfront.net/model-viewer-public/stats_track.min.js"
            document.getElementsByTagName("head")[0].appendChild(script1);
            document.getElementsByTagName("head")[0].appendChild(script2);
            document.getElementsByTagName("head")[0].appendChild(script3);
            document.getElementsByTagName("head")[0].appendChild(script4);
            document.getElementsByTagName("head")[0].appendChild(script5);
            document.getElementsByTagName("head")[0].appendChild(script6);
            document.getElementsByTagName("head")[0].appendChild(script7);
            document.getElementsByTagName("head")[0].appendChild(script8);

            $('model-viewer').css({
                'width' : $modelEle.width(),
                'height' : $modelEle.width()
            });
            $modelEle.addClass('model-loaded');
        } else if ($currentActiveContainer.hasClass('emersya-container')) {
            $('.product-carousel').slick('slickSetOption', 'swipe', false, false);
            var $modelEle = $('.product-carousel .emersya-container').not('.slick-cloned');
            var isAR = $('.slick-paging-3d.showcase-ar').length > 0 ? true : false;

            if (isAR) {
                $('.emersya-iframe').hide();
                $('.emersya-iframe-ar').show();
            } else {
                $('.emersya-iframe').show();
                $('.emersya-iframe-ar').hide();
            }
            $('.slick-paging-3d').removeClass('showcase-ar');

            //If model is already loaded, then return
            if (!isAR && $modelEle.hasClass('emersya-loaded')) return;
            if (isAR && $modelEle.hasClass('emersya-ar-loaded')) return;
            
            var emersyaURL = getEmersyaURL($modelEle, isAR);

            $('<iframe class="' + (isAR ? 'emersya-iframe-ar' : 'emersya-iframe') + '" src="' + emersyaURL + '" width="100%" height="100%" webkitallowfullscreen allow="camera; gyroscope; accelerometer; magnetometer; fullscreen;" ></iframe>')
            .appendTo($modelEle);

            if (isAR) {
                $modelEle.addClass('emersya-ar-loaded');
            } else {
                $modelEle.addClass('emersya-loaded');
            }
        } else {
            $('.product-carousel').slick('slickSetOption', 'swipe', true, false);
        }
    });
};

const init = () => {
    if ($('.emersya-container').length === 0) {
        bind3dModel();
    }
};

/**
 * Create emersya url
 * @param {jQuery} $modelEle  - Current container
 * @param {boolean} isShowcaseAR  - Display the AR directly or not
 * @returns {string} - Emersya URL
 */
function getEmersyaURL($modelEle, isShowcaseAR) {
    var productCode = $modelEle.attr('data-emersya-productcode');

    if (productCode.length === 0) return;

    var presetCode = $modelEle.attr('data-emersya-productpreset');
    var locale = $modelEle.attr('data-emersya-locale');

    if (locale === 'default' || locale.length === 0) {
        locale = 'en';
    }
    var emersyaURL = 'https://emersya.com';
    if (locale.length > 0) {
        emersyaURL += '/' + locale;
    }

    emersyaURL += (isShowcaseAR ? '/showcaseAR/' : '/showcase/') + productCode;

    if (presetCode.length > 0) {
        emersyaURL += '/' + presetCode;
    }

    return emersyaURL;
}

module.exports = {
    init,
    bind3dModel: bind3dModel
};
