/**
 * Display the selected country from the dropdown
 * @param {element} storeSwitcherDropdown - dropdown from where we need to fetch selected item
 * @returns {void}
 */
function displaySelectedCountry(storeSwitcherDropdown) {
    const selectedCountry = $(storeSwitcherDropdown).find('li.selected');
    $(selectedCountry).addClass('d-none');
    $(storeSwitcherDropdown).find('.country-selector_button .current-country').html($(selectedCountry).find('a').html());
}

module.exports = function () {
    $(window).on('load', function () {
        // calculate the top padding for the main section
        $('body').trigger('calculatemainsection');
    });
    if ($('.store-switcher-wrapper').length) {

        const storeSwitcherDropdown = $('.store-switcher-storeswitcher .dropdown');
        var isStoreSwitcherOkBtnClicked = false;

        // dropdown expand/collapse
        var countrySelectorBtn = $(storeSwitcherDropdown).find('.country-selector_button');
        $(countrySelectorBtn).find('.far').on('click', function (e) {
            $(countrySelectorBtn).trigger('click');    
        });
        $(countrySelectorBtn).on('click', function (e) {
            if (e.target !== this) {
                return;
            }

            if ($(this).hasClass('collapsed')) {
                $(this).next('.dropdown-menu').addClass('show');
                $(this).removeClass('collapsed').addClass('expanded');
            } else {
                $(this).next('.dropdown-menu').removeClass('show');
                $(this).removeClass('expanded').addClass('collapsed');
            }
        });

        // Dropdown item click
        $(storeSwitcherDropdown).find('a').on('click', function (e) {
            e.preventDefault();
            if (isStoreSwitcherOkBtnClicked) {
                window.isDropDownItemClicked = false;
            } else {
                $(storeSwitcherDropdown).find('li.selected').removeClass('selected d-none');
                $(storeSwitcherDropdown).find('.country-selector_button').removeClass('expanded').addClass('collapsed');
                $(storeSwitcherDropdown).find('.dropdown-menu').removeClass('show');
                $(this).parent('li').addClass('selected');
                displaySelectedCountry(storeSwitcherDropdown);
                window.isDropDownItemClicked = true;
            }
        });

        // Bind Ok button
        $('.store-switcher-ok-btn').on('click', function () {
            const selectedCountryAnchor = $(storeSwitcherDropdown).find('li.selected a');
            isStoreSwitcherOkBtnClicked = true;
            sessionStorage.setItem('hideRedirectionBanner', true);
            $(selectedCountryAnchor).trigger('click');
        });

        // Bind close
        $('.store-switcher-close').on('click', function () {
            sessionStorage.setItem('hideRedirectionBanner', true);
            $('.store-switcher-wrapper').addClass('d-none');
            $('body').trigger('calculatemainsection');
        });

        if (!sessionStorage.getItem('hideRedirectionBanner')) {
            $('.store-switcher-wrapper').removeClass('d-none');
            $('body').trigger('calculatemainsection');
        }
    }
};
