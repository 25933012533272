'use strict';

/**
 * Function to load a youtube video in the pdp image carousel
 */
module.exports = function () {
    $('.product-carousel').on('afterChange', function (event, slick, currentSlide, nextSlide) {
        var $currentActiveContainer = $('.carousel-item[data-slick-index="' + currentSlide + '"');
        if ($currentActiveContainer.hasClass('video-container')) {
            $('.product-carousel').slick('slickSetOption', 'swipe', false, false);
            var $videoEle = $('.product-carousel .video-container');

            // If model is already loaded, then return
            if ($videoEle.hasClass('video-loaded')) return;

            var videoThumbnail = $videoEle.attr('data-video-thumbnail');

            if (videoThumbnail.length === 0) return;

            var videoUrl = $videoEle.attr('data-video-url');

            // Youtube URL
            $('<iframe class="video" src="' + videoUrl + '" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>')
                .appendTo($videoEle);

            $videoEle.addClass('video-loaded');
        }
    });
};
