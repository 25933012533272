'use strict';

module.exports = function () {
    if ($('.banner-carousel').length > 0) {
        $('.banner-carousel').slick();
    }

    /**
     * Load Vimeo Video on banner
     */
    function loadVideo() {
        $('.experience-component .banner-image').each(function () {
            var $videoContainer = $(this);
            var desktopVideoURL = $videoContainer.data('desktop-video-url');
            var mobileVideoURL = $videoContainer.data('mobile-video-url');
            var videoURL = window.innerWidth >= 992 ? desktopVideoURL : mobileVideoURL;

            if (window.innerWidth < 992 && !mobileVideoURL) {
                return; // Don't load the video on mobile if mobile video is not available
            }

            // Clear previous video content
            $videoContainer.empty();

            // Create new Vimeo div
            var $vimeoDiv = $('<div>', {
                'data-vimeo-url': videoURL,
                'data-vimeo-autoplay': 'true',
                'data-vimeo-loop': 'true',
                'data-vimeo-controls': 'false',
                'data-vimeo-muted': 'true',
                'data-vimeo-playsinline': 'true',
                'data-vimeo-responsive': 'true'
            });

            // Append the Vimeo div to the container
            $videoContainer.append($vimeoDiv);

            // Initialize Vimeo player
            new Vimeo.Player($vimeoDiv[0]);
        });
    }

    // Check if there are any videos on the page
    var $videoElements = $('.experience-component .banner-image').filter(function () {
        return $(this).data('desktop-video-url') || $(this).data('mobile-video-url');
    });

    if ($videoElements.length > 0) {
        // Track the current mode (mobile or desktop)
        var currentMode = window.innerWidth >= 992 ? 'desktop' : 'mobile';

        // Load video on page load
        $(document).ready(function () {
            loadVideo();
        });

        // Register resize event listener only if there is at least one video
        $(window).resize(function () {
            var newMode = window.innerWidth >= 992 ? 'desktop' : 'mobile';

            // Only reload video if the mode has changed
            if (newMode !== currentMode) {
                currentMode = newMode;
                loadVideo();
            }
        });
    }
};
