'use strict';

let $cache = {
    pdWidgetWrapper: $('.msrt-wrapper'),
    pdWidget: $('#msrt-container')
};

/**
 * Launch Pupillary Distance Widget
 */
function launchMsrt() {
    $cache.pdWidgetWrapper.show();
    const params = {
        apiKey: $cache.pdWidget.attr('data-api-key'),
        ...(JSON.parse($cache.pdWidget.attr('data-theme-config') || {})),
    };
    const $resultWrapper = $('.js-pd-result-wrapper');
    const $launchPDBtn = $('.js-launch-pd');
    
    $resultWrapper.addClass('d-none');
    $('body').addClass('modal-open');

    window.closeMsrt = function () {
        let scrollTop = 0;
        $cache.pdWidgetWrapper.hide();
        $cache.pdWidget.html('');
        $('body').removeClass('modal-open');
        if ($('.js-customer-pd').html()) {
            $resultWrapper.removeClass('d-none');
            $launchPDBtn.addClass('d-none');
        } else {
            $resultWrapper.addClass('d-none');
            $launchPDBtn.removeClass('d-none');
        }
    };

    Msrt.createWidget("msrt-container", {
        ...params,
        onGetResultPd: (result) => {
            let pdValue = result.pd;
            if (result.left && result.right) {
                pdValue += '|' + result.left + '|' + result.right;
            }
            $('body').trigger('rxForm:PDResultReceived', [pdValue]);
        },
        onTooManyErrors: () => { console.log('Errors PD widget'); closeMsrt(); },
        onClose: () => {
            window.closeMsrt();
        }
    });
};

module.exports = {
    init: async function init() {
        $('.js-launch-pd').off('click').on('click', function () {
            launchMsrt();
        });
        $('body').on('rxForm:PDResultReceived', function (e, pdValue) {
            $('.js-customer-pd').html(pdValue);
            window.closeMsrt();
        });
    }
};
