'use strict';

const jstz = require('jstz');
const moment = require('moment-timezone');

module.exports = function () {
    const tz = jstz.determine() || 'UTC';

    $('[data-parse-date]').each(function () {
        const useCustomDate = $(this).data('use-custom-date') === true;

        if (useCustomDate) {
            const countryCode = $(this).data('countrycode') ? $(this).data('countrycode').toLowerCase() : 'en';
            const parseDateFormat = $(this).data('parse-date') || 'DD-MM-YYYY';
            const dateValue = $(this).text().trim();

            const localTimestamp = moment(dateValue, parseDateFormat)
                .locale(countryCode)
                .format(parseDateFormat);

            $(this).text(localTimestamp);
        } else {
            const localTimestamp = moment()
                .tz(tz.name())
                .locale($(this).data('countrycode').toLowerCase() || 'en')
                .format($(this).data('parse-date') || 'MMMM Do YYYY, h:mm a');

            $(this).text(localTimestamp);
        }
    });
};
